export enum DrawerName {
    NAVIGATION = 'navigation',
    FILTER = 'filter',
    RESIZE = 'resize',
    CROP = 'crop',
    TRANSFORM = 'transform',
    DRAW = 'draw',
    TEXT = 'text',
    SHAPES = 'shapes',
    STICKERS = 'stickers',
    FRAME = 'frame',
    CORNERS = 'corners',
    BACKGROUND = 'background',
    MERGE = 'merge',
    OBJECT_SETTINGS = 'objectSettings',

    LOGO = 'logo'
}
