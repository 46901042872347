import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {Settings} from 'common/core/config/settings.service';
import {OverlayPanelRef} from 'common/core/ui/overlay-panel/overlay-panel-ref';
import {FormControl, FormGroup} from '@angular/forms';
import {CanvasService} from '../../../image-editor/canvas/canvas.service';
import {ImportToolService} from '../../../image-editor/tools/import/import-tool.service';
import {BehaviorSubject} from 'rxjs';
import {SampleImage} from "../open-sample-image-panel/sample-image";
import {UploadedLogo} from "../../../models/uploaded-logo.model";
import {FilterToolService} from "../../../image-editor/tools/filter/filter-tool.service";

@Component({
    selector: 'logo-preview-panel',
    templateUrl: './logo-preview-panel.component.html',
    styleUrls: ['./logo-preview-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class LogoPreviewPanelComponent {

    public currentLogo: UploadedLogo;
    public sampleImages: SampleImage[];

    private removeWhiteCb: boolean = false;

    constructor(
        public importTool: ImportToolService,
        private config: Settings,
        private panelRef: OverlayPanelRef<LogoPreviewPanelComponent>,
        private canvas: CanvasService,
        private filterTool: FilterToolService
    ) {
        this.sampleImages = this.config.get('logostudio.ui.openImageDialog.sampleImages');
    }

    public openUploadDialog() {
        this.importTool.openUploadDialog({openAsBackground: true}).then(() => this.close());
    }

    public openLogo() {

        let filters = this.removeWhiteCb ? ["removeColor"] : [];

        return this.importTool.openFile(this.currentLogo.logo_url, this.currentLogo.logo_file_extension, true, filters).then(() => {
            this.canvas.state.contentLoadingState$.next({name: this.currentLogo.logo_file_extension === 'json' ? 'state' : 'overlayImage', loading: false});

            this.close()
        });
    }

    public openSample(sample: SampleImage) {
        if (typeof sample.action === 'function') {
            sample.action();
        } else {
            const sampleUrl = this.getSampleUrl(sample);
            if (sampleUrl.endsWith('.json')) {
                this.importTool.loadStateFromUrl(sampleUrl).then(() => this.close());
            } else {
                this.importTool.openBackgroundImage(sampleUrl)
                    .then(() => this.close());
            }
        }
    }

    public close() {
        this.panelRef.close();
    }

    public removeWhite(checked) {
        this.removeWhiteCb = checked
    }

    public getSampleUrl(image: SampleImage, useThumbnail = false) {
        const url = (image.thumbnail && useThumbnail) ? image.thumbnail : image.url;
        // prefix relative link with base url, if needed
        if (url.indexOf('//') === -1) {
            return this.config.getAssetUrl(url, true);
        } else {
            return url;
        }
    }
}
