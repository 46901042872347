import {Injectable} from "@angular/core";
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {Product} from "../models/product.model";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {BaseService} from "./base-service";
import {HttpClient} from "@angular/common/http";
import {ProductImage} from "../models/product-image.model";
import {Settings} from "@common/core/config/settings.service";
import {
    API_LOGO_URL,
    API_PRODUCT_SHOP_URL,
    API_PRODUCT_URL,
    COOKIE_UPLOADS_NAME
} from "../image-editor/default-settings";
import {UploadedLogo} from "../models/uploaded-logo.model";
import {CookieService} from "ngx-cookie-service";

@Injectable()
export class ProductService extends BaseService{

    private apiExternalUrl // URL to web api
    private apiShopUrl // URL to web api
    private apiLogoUrl // URL to web api

    private _product: Product;
    private _product$ = new BehaviorSubject<Product>(this._product);

    public session_id: string = "";
    public idLogo: string; // the GET param from the URL of the logo to be loaded
    public uploadedLogo: UploadedLogo;

    constructor(protected http: HttpClient,
                protected config: Settings,
                private cookies: CookieService,) {
        super(http, config);

        this.apiUrl = this.config.getBaseEndpoint()
        this.apiExternalUrl = this.apiUrl + API_PRODUCT_URL
        this.apiShopUrl = this.apiUrl + API_PRODUCT_SHOP_URL
        this.apiLogoUrl = this.apiUrl + API_LOGO_URL
    }

    // https://stackoverflow.com/questions/52949215/how-to-subscribe-on-variable-changes
    get product() {
        return this._product$.asObservable();
    }

    getProduct(idUser: string, idProduct: string, lang: string, idImage?: string, filterName?: string, idLogo?: string): Observable<Product> {
        const url = `${this.apiExternalUrl}/${idUser}/${idProduct}/${lang}/1`; // last /1 is to get the product with colors
        return this.http.get<Product>(url)
            .pipe(

                map(res => new Product().deserialize(res)),

                tap(product => {
                    this.log(`fetched product id=${idUser}`);

                    // set the image for the product
                    var image = null;
                    if(idImage) {
                        image = product.getImageById(idImage)
                    }
                    else {
                        image = product.getImageByUrl(product.default_image)
                    }
                    // fallback to first image if nothing is found
                    if(!image) image = product.images[0];
                    product.setSelectedImage(image);

                    // set filter if it exists in the URL. This will override the filter from the db
                    if(filterName) product.selected_filter = filterName

                    // set observable product
                    this._product = product;
                    this._product$.next(product);

                    // handle other stuff
                    this.idLogo = idLogo;
                }),
                catchError(this.handleError<Product>(`getProduct external | id=${idUser}`))
            );
    }

    getProductForShop(idUser: string, idProduct: string, lang: string, variations?: String, idImage?: string, idLogo?: string): Observable<Product> {
        const url = `${this.apiShopUrl}/${idUser}/${idProduct}/${variations}/${lang}/1`; // last /1 is to get the product with colors
        return this.http.get<Product>(url)
            .pipe(

                map(res => new Product().deserialize(res)),

                tap(product => {
                    this.log(`fetched product id=${idUser}`);

                    // set the image for the product
                    var image = null;
                    if(idImage) {
                        image = product.getImageById(idImage)
                    }
                    else {
                        image = product.getImageByUrl(product.default_image)
                    }
                    // fallback to first image if nothing is found
                    if(!image)
                        image = product.images[0];

                    product.setSelectedImage(image);

                    // set observable product
                    this._product = product;
                    this._product$.next(product);

                    // handle other stuff
                    this.idLogo = idLogo;
                }),
                catchError(this.handleError<Product>(`getProduct for shop | id=${idProduct}`))
            );
    }

    /**
     * Loads a logo when a product is opened
     */
    loadLogo(): Observable<UploadedLogo> {
        // try to get the logo from the API if it's set as a url param else try to load from cookie
        if(this.idLogo)
            return this.loadUploadedLogoFromApi(this.idLogo)
        else
            return this.loadUploadedLogoBasedOnCookie()
    }

    loadUploadedLogoFromApi(idLogo): Observable<UploadedLogo> {
        const url = `${this.apiLogoUrl}/${idLogo}`;
        return this.http.get<UploadedLogo>(url)
            .pipe(
                map(res => new UploadedLogo().deserialize(res)),
                tap(logo => {
                    this.uploadedLogo = logo
                    console.log(logo)
                }),
                catchError(this.handleError<UploadedLogo>(`error occurred loading logo | id=${idLogo}`))
            );
    }

    loadUploadedLogoBasedOnCookie(): Observable<UploadedLogo> {
        if(!this.cookies.get(COOKIE_UPLOADS_NAME)) return

        let logo = JSON.parse(this.cookies.get(COOKIE_UPLOADS_NAME)) // for now holds a single logo - id and url
        if(!logo)
            return

        this.uploadedLogo = new UploadedLogo()
        this.uploadedLogo.id = logo.id
        this.uploadedLogo.logo_url = logo.url

        return of(this.uploadedLogo)
    }

    getProducts(): Observable<Product[]> {
        return this.http.get<Product[]>(this.apiUrl)
            .pipe(
                tap(_ => this.log('fetched products')),
                catchError(this.handleError<Product[]>('getProducts', []))
            );
    }

    setSelectedImage(image: ProductImage) {
        this._product.setSelectedImage(image);
        this._product$.next(this._product);
    }

    setFilter(filterName: string) {

    }

    /**
     * return the id of the uploaded logo
     * if uploaded logo is set it return that if not it will look in the cookie
     */
    getUploadedLogoId() {

    }
}
