

//https://nehalist.io/working-with-models-in-angular/
//https://angular.io/tutorial/toh-pt6

import {ProductImage} from "./product-image.model";
import {Deserializable} from "./deserializable.model";
import {ProductColor} from "./product-color.model";

export class Product implements Deserializable {
    id: string;
    name: string;
    default_image: string;
    images: ProductImage[]
    colors: ProductColor[]
    filters: string[]

    selected_image: ProductImage // current product image - loaded in the editor
    selected_filter: string;

    deserialize(input: any): this {

        let obj = input.data

        Object.assign(this, obj);

        this.filters = JSON.parse(obj.filters)

        if(obj.images && obj.images.length)
            this.images = obj.images.map((image: ProductImage) => new ProductImage().deserialize(image));

        if(obj.colors && obj.colors.length)
            this.colors = obj.colors.map((color: ProductColor) => new ProductColor().deserialize(color));

        // we cannot use deserialize because it will create an infinite loop - see ProduceColor
        this.setImagesToColors();

        return this;
    }

    private setImagesToColors() {
        // first make sure the colors have the correct images (deserialized and everything)
        var i = 0, c = 0;
        for(let color of this.colors) {
            this.colors[c].images = [] // reset images - clean slate
            for(let image of this.images) {
                if(image.color && image.color.id == color.id) {
                    this.colors[c].images.push(image) // set image to color
                }
            }
            i++; c++;
        }

        // next make sure the images have the correct colors assigned
        i = 0, c = 0;
        for(let color of this.colors) {
            i = 0;
            for(let image of this.images) {
                if(image.color && image.color.id == color.id) {
                    //this.colors[c].images.push(image) // set image to color
                    this.images[i].color = this.colors[c] // update the color in image
                }
                i++;
            }
            c++;
        }
    }

    public setSelectedImage(image: ProductImage) {
        this.selected_image = image;
    }

    public getImageById(id: string) {
        return this.images.find(o => o.id_external === id);
    }

    public getImageByUrl(url: string) {
        return this.images.find(o => o.url === url);
    }
}
