import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable, of } from 'rxjs';
import {Settings} from "@common/core/config/settings.service";

@Injectable()
export class BaseService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    protected baseUrl = "https://app.papionne.com/api/v1/";
    public apiUrl;

    constructor(protected http: HttpClient,
                protected config: Settings) {

        this.baseUrl = this.config.getBaseEndpoint()
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    protected handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            //this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    protected log(message: string) {
        //this.messageService.add(`HeroService: ${message}`);
        console.log(`ProductService: ${message}`)
    }
}