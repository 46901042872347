import { HttpClientModule } from '@angular/common/http';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService }  from './services/in-memory-data.service';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {ApplicationRef, ErrorHandler, Injector, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Settings} from 'common/core/config/settings.service';
import {MERGED_CONFIG} from './image-editor/default-settings';
import {ImageEditorUIModule} from './image-editor-ui/image-editor-ui.module';
import {noBackendErrorHandlerFactory} from 'common/core/errors/no-backend-error-handler';
import {MatIconRegistry} from "@angular/material/icon";
import {RouterModule} from "@angular/router";
import {APP_BASE_HREF} from '@angular/common';

@NgModule({
    declarations: [
        AppComponent
    ],
    entryComponents: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        ImageEditorUIModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
        // and returns simulated server responses.
        // Remove it when a real server is ready to receive requests.
        // The passThruUnknownUrl allows the HttpClient to send request to the
        // real API for paths not declared in the object returned by CreateDb method
        // InMemoryWebApiModule.forRoot(
        //     InMemoryDataService, {
        //         dataEncapsulation: false,
        //         passThruUnknownUrl: true
        //     }
        // ),
        RouterModule.forRoot([]),
    ],
    providers: [
        {
            provide: ErrorHandler,
            useFactory: noBackendErrorHandlerFactory,
            deps: [Settings],
        },
        {provide: APP_BASE_HREF, useValue : '/' }
    ],
})

export class AppModule {
    constructor(private injector: Injector) {}

    ngDoBootstrap(app: ApplicationRef) {
        const selector = this.injector.get(MERGED_CONFIG).selector;
        app.bootstrap(AppComponent, selector);
    }
}
