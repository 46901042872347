import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageEditorComponent} from './image-editor.component';
import {HistoryPanelComponent} from './panels/history-panel/history-panel.component';
import {ObjectsPanelComponent} from './panels/objects-panel/objects-panel.component';
import {ImprintAreaPanelComponent} from './panels/imprint-area-panel/imprint-area-panel.component';
import {ImageEditorModule} from '../image-editor/image-editor.module';
import {ToolbarControlsModule} from './toolbar-controls/toolbar-controls.module';
import {EditorControlsService} from './toolbar-controls/editor-controls.service';
import {FloatingPanelsService} from './toolbar-controls/floating-panels.service';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule} from '@angular/material/slider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from "@angular/material/card";
import {CustomScrollbarModule} from '@common/core/ui/custom-scrollbar/custom-scrollbar.module';
import {OverlayPanel} from '@common/core/ui/overlay-panel/overlay-panel.service';
import {OverlayContainer} from '@angular/cdk/overlay';
import {EditorOverlayContainer} from './panels/editor-overlay-container';
import {OpenSampleImagePanelComponent} from './panels/open-sample-image-panel/open-sample-image-panel.component';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {BackgroundImageDirective} from './background-image.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DomSanitizer} from '@angular/platform-browser';
import {ExportPanelComponent} from './panels/export-panel/export-panel.component';
import {Settings} from '@common/core/config/settings.service';
import {ThemeService} from '@common/core/theme.service';
import {
    DEFAULT_DARK_COLORS,
    DEFAULT_LIGHT_COLORS,
    DEFAULT_THEMES
} from './default-themes';
import {CssTheme} from '@common/core/types/models/CssTheme';
import {UploadsModule} from '@common/uploads/uploads.module';
import cssVars from 'css-vars-ponyfill';
import { ImageLoadingPanelComponent } from './panels/image-loading-panel/image-loading-panel.component';
import { ProductImagesDrawerComponent} from "./toolbar-controls/drawers/product-images-drawer/product-images-drawer.component";
import {UploadLogoPanelComponent} from "./panels/upload-logo-panel/upload-logo-panel.component";
import {A11yModule} from "@angular/cdk/a11y";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {CdkStepperModule} from "@angular/cdk/stepper";
import {CdkTableModule} from "@angular/cdk/table";
import {CdkTreeModule} from "@angular/cdk/tree";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatBadgeModule} from "@angular/material/badge";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDividerModule} from "@angular/material/divider";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatStepperModule} from "@angular/material/stepper";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSelectModule} from "@angular/material/select";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTreeModule} from "@angular/material/tree";
import {PortalModule} from "@angular/cdk/portal";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {LogoPreviewPanelComponent} from "./panels/logo-preview-panel/logo-preview-panel.component";

export function init_icons(config: Settings, icons: MatIconRegistry, sanitizer: DomSanitizer, themes: ThemeService) {
    return () => {
        // init icons
        const url = config.getAssetUrl('icons/merged.svg', true);
        icons.addSvgIconSet(
            sanitizer.bypassSecurityTrustResourceUrl(url)
        );
        // init themes
        const customThemes = config.get('logostudio.ui.themes') as CssTheme[],
            mergedThemes = {...DEFAULT_THEMES};
        if (customThemes && customThemes.length) {
            customThemes.forEach(theme => {
                const defaultColors = theme.is_dark ? DEFAULT_DARK_COLORS : DEFAULT_LIGHT_COLORS,
                    mergedTheme = {...theme, colors: {...defaultColors, ...theme.colors}};
                mergedThemes[mergedTheme.name] = mergedTheme;
            });
        }

        const rootEl = document.documentElement.querySelector('logostudio-editor') as HTMLElement;

        themes.setRootEl(rootEl);
        themes.registerThemes(mergedThemes);

        const isNativeSupport = typeof window !== 'undefined' &&
            window['CSS'] &&
            window['CSS'].supports &&
            window['CSS'].supports('(--a: 0)');
        if ( ! isNativeSupport) {
            cssVars({variables: themes.selectedTheme$.value.colors});
        }
        return new Promise(resolve => resolve());
    };
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ImageEditorModule,
        ToolbarControlsModule,
        CustomScrollbarModule,
        UploadsModule,

        // material
        // MatSliderModule,
        // MatRadioModule,
        // MatButtonModule,
        // MatIconModule,
        // DragDropModule,
        // MatTooltipModule,
        // MatDialogModule,
        // MatCardModule,

        // Material
        MatExpansionModule,
        A11yModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        PortalModule,
        ScrollingModule,
    ],
    declarations: [
        ImageEditorComponent,
        HistoryPanelComponent,
        ObjectsPanelComponent,
        ImprintAreaPanelComponent,
        UploadLogoPanelComponent,
        OpenSampleImagePanelComponent,
        BackgroundImageDirective,
        ExportPanelComponent,
        ImageLoadingPanelComponent,
        ProductImagesDrawerComponent,
        LogoPreviewPanelComponent
    ],
    exports: [
        ImageEditorComponent,
        ProductImagesDrawerComponent,


        // Material
        MatExpansionModule,
        A11yModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        PortalModule,
        ScrollingModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: init_icons,
            deps: [Settings, MatIconRegistry, DomSanitizer, ThemeService],
            multi: true,
        },
        EditorControlsService,
        FloatingPanelsService,
        OverlayPanel,
        {provide: OverlayContainer, useClass: EditorOverlayContainer},
    ],
})
export class ImageEditorUIModule {}
