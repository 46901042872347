<div class="image-editor-bg" backgroundImage="images/viewport-bg.png"></div>
<toolbar *ngIf="!(toolbarHidden$ | async)"></toolbar>

<editor-controls *ngIf="(navPosition$ | async) === 'top'" class="position-top"></editor-controls>

<div class="canvas-wrapper" #canvasWrapper [fileDropzone]="dropzoneConfig" [disableDropzoneClick]="true" (filesDropped)="onFileDropped($event)">
    <div class="canvas-mask-wrapper" #canvasMaskWrapper>
        <div class="bg-wrapper" backgroundImage="images/canvas-bg.png" [@fadeInCanvas]="loadState.canvasVisible">
            <canvas id="logostudio-canvas"></canvas>
        </div>
        <div class="image-loading-message" [@imageLoadingAnimation] *ngIf="loadState.messageVisible">{{loadState.message}}...</div>
    </div>
</div>


<product-images-drawer></product-images-drawer>

<editor-controls *ngIf="(navPosition$ | async) === 'bottom'" class="position-bottom"></editor-controls>

<div class="papionne" *ngIf="!config.isPresentationMode()">
    <a href="https://papionne.com/?utm_source=logo-studio&utm_medium=footer" target="_blank">
        <img src="assets/images/papionne-small.svg">
    </a>
</div>
