import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {ProductService} from "../../../../services/product.service";
import {ProductImage} from "../../../../models/product-image.model";
import {Select, Store} from "@ngxs/store";
import {ChangeProductImage} from "../../../state/product/product.actions";
import {Settings} from "@common/core/config/settings.service";
import {EditorState} from "../../../../image-editor/state/editor-state";
import {BehaviorSubject, Observable} from "rxjs/index";
import {ProductUI} from "../../../../image-editor/default-settings";
import {DrawToolService} from "../../../../image-editor/tools/draw/draw-tool.service";
import {ProductColor} from "../../../../models/product-color.model";

@Component({
    selector: 'product-images-drawer',
    templateUrl: './product-images-drawer.component.html',
    styleUrls: ['./product-images-drawer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ProductImagesDrawerComponent {

    @Select(EditorState.productUI) productUI$: Observable<ProductUI>;

    public images: ProductImage[];
    public colors: ProductColor[];

    private hiddenFromConfig: boolean = false;

    public selectedColor$ = new BehaviorSubject(null);
    public hideProductImage$: boolean;

    constructor(
        protected productService: ProductService,
        private store: Store,
        public config: Settings,
        public drawTool: DrawToolService,
    ) {
        this.productService.product.subscribe(
            (product) => {
                if (product) {
                    this.colors = product.colors
                    this.images = product.selected_image.color.images

                    this.selectedColor$.next(product.selected_image.color)
                }
            }
        )
    }

    public ngOnInit() {
        this.productUI$.subscribe(
            (productUI) => {
                if(productUI) {
                    this.hideProductImage$ = productUI.hideProductImages
                }
            }
        )
    }

    public changeProductImage(image: ProductImage) {
        this.store.dispatch(new ChangeProductImage(image));
    }

    public changeColor(color: ProductColor) {
        this.selectedColor$.next(color)
        this.images = color.images
        this.store.dispatch(new ChangeProductImage(color.images[0]));
    }
}
