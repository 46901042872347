import {Injectable, Injector} from '@angular/core';
import {fabric} from 'fabric';
import {IEvent, Image, Image as FImage, Object} from 'fabric/fabric-impl';
import {CanvasPanService} from './canvas-pan.service';
import {ActiveObjectService} from './active-object/active-object.service';
import {CanvasZoomService} from './canvas-zoom.service';
import {Settings} from 'common/core/config/settings.service';
import {staticObjectConfig} from '../objects/static-object-config';
import {CanvasStateService, ContentLoadingStateName} from './canvas-state.service';
import {Select, Store} from '@ngxs/store';
import {ObjectNames} from '../objects/object-names.enum';
import {PathGroup} from "../../../../fabric-types/fabric-impl";
import {util} from "tinymce";
import JSON = util.JSON;
import {ProductService} from "../../services/product.service";
import {Product} from "../../models/product.model";
import {ImprintArea} from "../../models/imprint-area";
import * as _ from 'lodash';
import {ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {Location} from "@angular/common";
import {calculateAspectRatioFit} from "../utils/image-manipulation";
import {CookieService} from 'ngx-cookie-service'
import {COOKIE_UPLOADS_NAME, ProductUI} from "../default-settings";
import {HttpClient} from "@angular/common/http";
import {FilterToolService} from "../tools/filter/filter-tool.service";
import {ApplyFilter} from "../../image-editor-ui/state/filter/filter.actions";
import {Observable} from "rxjs/index";
import {EditorState} from "../state/editor-state";

@Injectable()
export class CanvasService {

    public hideImprintArea$: boolean;

    private readonly minWidth: number = 50;
    private readonly minHeight: number = 50;

    public imprintArea: PathGroup
    public product: Product
    public queryParams = null

    private filterTool: FilterToolService;

    constructor(
        public pan: CanvasPanService,
        public zoom: CanvasZoomService,
        public state: CanvasStateService,
        //public activeObject: ActiveObjectService,
        public productService: ProductService,
        //public filterTool: FilterToolService,
        //protected router: Router,
        //protected activeRoute: ActivatedRoute,
        //private location: Location,
        private config: Settings,
        //private store: Store,
        private cookies: CookieService,
        private http: HttpClient,
        private injector: Injector
    ) {
        this.productService.product.subscribe(
            (product) => {
                if(product) {
                    this.product = product
                }

                // handle imprint area
                this.hideImprintArea$ = this.config.get("logostudio.ui.productUI.hideImprintArea");
            }
        )

        // TODO fix this crap
        let self = this
        setTimeout(function() {
            self.state.fabric.on('mouse:over', function(e) {
                //e.target.set('fill', 'red');    // TODO use 'e' to toggle handles for logos
                self.hideImprintArea(false)
                self.state.fabric.renderAll();
            });

            self.state.fabric.on('mouse:out', function(e) {
                self.hideImprintArea(true)
                self.state.fabric.renderAll();

                // when leaving the canvas also save the design
                // mouse:out is triggered when leaving objects not just the canvas. Account for this to reduce the number of server requests
                // http://fabricjs.com/events
                if(!e.target && !self.config.get('logostudio.disableAutoSaveDesign'))

                    self.saveDesign(null);
            });
        }, 3000);


        // add canvas events to toggle imprint area


        /*location.onUrlChange(url => console.log("location", url));

        this.activeRoute.queryParams.subscribe(
            params => {
                this.queryParams = params
                console.log("params", params)

            }
        );

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator

                console.log(event);

            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator

                console.log(event);
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });*/
    }


    public render() {
        this.state.fabric.requestRenderAll();
    }

    public fabric(): fabric.Canvas {
        return this.state.fabric;
    }

    public getObjectById(id: string): Object|null {
        return this.state.fabric.getObjects().find(obj => {
            return obj.data && obj.data.id === id;
        });
    }

    public resize(width: number, height: number) {
        this.state.fabric.setWidth(width * this.zoom.getScaleFactor());
        this.state.fabric.setHeight(height * this.zoom.getScaleFactor());
        this.state.original.width = width;
        this.state.original.height = height;
    }

    public loadMainImage(url: string, clearCanvas = true, loadStateName: ContentLoadingStateName = 'mainImage'): Promise<Image> {
        return new Promise(resolve => {
            this.state.contentLoadingState$.next({name: loadStateName, loading: true});
            //this.loadImage(url).then(img => {
            fabric.util.loadImage(url, image => {
                if ( ! clearCanvas) {
                    const bgImage = this.getMainImage();
                    this.fabric().remove(bgImage);
                } else {
                    this.fabric().clear();
                }
                const img = new fabric.Image(image);
                img.set(staticObjectConfig);
                img.name = ObjectNames.mainImage.name;
                this.state.fabric.add(img);
                this.resize(img.width, img.height);
                this.zoom.fitToScreen();
                this.state.contentLoadingState$.next({name: loadStateName, loading: false});
                resolve(img);

                if(this.getMainImage())
                    this.getMainImage().sendToBack();

                const callback = this.config.get('logostudio.onMainImageLoaded');
                if (callback) callback(img);


                // handle imprint area
                this.loadImprintArea()

                },
                null,
                this.config.get('logostudio.crossOrigin')
            );
        });
    }

    public loadImage(data: string): Promise<Image> {
        return new Promise(resolve => {
            fabric.util.loadImage(
                data,
                img => resolve(new fabric.Image(img)),
                null,
                this.config.get('logostudio.crossOrigin')
            );
        });
    }

    public openNew(width: number, height: number): Promise<{width: number, height: number}> {
        width = width < this.minWidth ? this.minWidth : width;
        height = height < this.minHeight ? this.minHeight : height;

        this.state.fabric.clear();
        this.resize(width, height);

        return new Promise(resolve => {
            setTimeout(() => {
                this.zoom.fitToScreen();
                this.state.contentLoadingState$.next({name: 'blank', loading: false});
                resolve({width, height});
            });
        });
    }

    // public changeProductImage(id: number) {
    //     let image = this.product.getImageById(id)
    //     this.loadMainImage(image.image, false).then(() => {
    //
    //     });
    // }

    /**
     * Open image at given url in canvas.
     */
    public openImage(url, fitToScreen = true, withFilters = []): Promise<Image> {
        return new Promise(resolve => {
            fabric.util.loadImage(url, image => {
                if ( ! image) return;

                // remove existing logo if set as such
                if(this.config.get('logostudio.alwaysReplaceLogo')) {
                    this.state.fabric.remove( this.getObjectByName(ObjectNames.image.name))
                }

                const object = new fabric.Image(image);
                object.name = ObjectNames.image.name;
                object.opacity = 0;

                // use either main image or canvas dimensions as outer boundaries for scaling new image
                var maxWidth  = this.state.original.width,
                    maxHeight = this.state.original.height;

                // if image is wider or higher then the current canvas, we'll scale it down
                if (fitToScreen && (object.width >= maxWidth || object.height >= maxHeight)) {

                    // calc new image dimensions (main image height - 10% and width - 10%)
                    const newWidth  = maxWidth - (0.1 * maxWidth),
                        newHeight = maxHeight - (0.1 * maxHeight),
                        scale     = 1 / (Math.min(newHeight / object.getScaledHeight(), newWidth / object.getScaledWidth()));

                    // scale newly uploaded image to the above dimensions
                    object.scaleX = object.scaleX * (1 / scale);
                    object.scaleY = object.scaleY * (1 / scale);
                }

                // handle logo resize based on imprint area
                this.scaleImageToFitImprintArea(object)

                // center and render newly uploaded image on the canvas or imprint area
                this.state.fabric.add(object);
                this.centerImageInContainer(object);
                object.setCoords();
                this.render();
                this.zoom.fitToScreen();

                // handle clipping //
                //object.clipTo = ctx => this.clipToImprintArea(ctx)
                // object.clipTo = function (ctx) {
                //     return _.bind(self.clipToImprintArea, this)(ctx, self);
                // }
                this.attachClippingFunction(object)

                // apply image filter
                this.attachFilters(object, withFilters);

                object.animate('opacity', '1', {
                    duration: 425,
                    onChange: () => {
                        this.render();
                    },
                    onComplete: () => {
                        resolve(object);
                        this.saveDesign(null);
                    }
                });
            },
                null,
                this.config.get('logostudio.crossOrigin')
            );
        });
    }

    /**
     * Opens the last uploaded logo.
     * All uploaded logos are store in cookies
     */
    public openLastUploadedImage() {
        // console.log(this.productService.uploadedLogo)
        // let logoUrl = this.productService.uploadedLogo ? this.productService.uploadedLogo.logo_url : null
        // if(logoUrl)
        //     this.openImage(logoUrl);

        if(!this.productService.loadLogo())
            return

        this.productService.loadLogo().subscribe(uploadedLogo => {
            if(uploadedLogo)
                this.openImage(uploadedLogo.logo_url);
        })
    }

    /**
     * Get main image object, if it exists.
     */
    public getMainImage(): FImage {
        return this.state.fabric.getObjects()
            .find(obj => obj.name === ObjectNames.mainImage.name) as FImage;
    }

    /**
     * Listen to specified canvas event.
     */
    public on(eventName: string, handler: (e: IEvent) => void) {
        this.fabric().on(eventName, handler);
    }

    public jsonCanvasLoaded() {
        let logo = this.getObjectByName(ObjectNames.image.name);
        logo.opacity = 1; // this is a must

        this.imprintArea = this.getObjectByName(ObjectNames.imprintArea.name)
// console.log(this.state.fabric._objects)
// console.log(this.imprintArea )
// console.log(logo)
        // assign clipping mask
        this.attachClippingFunction(logo);

        //

    }

    public attachClippingFunction(object) {
        if(!this.imprintArea)
            return

        var self = this
        object.clipTo = function (ctx) {
            return _.bind(self.clipToImprintArea, object)(ctx, self);
        }
    }

    /**
     * Attaches the filters to the uploaded logo.
     * First it will attach the filters passed when uploading the logo, from the LogoPreviewPanel
     * Secondly iti will attach the filters set in the backend
     * @param object Logo
     * @param {string []} withFilters
     */
    public attachFilters(object, withFilters = []) {
        let self = this;

        if(!this.filterTool)
            this.filterTool = this.injector.get(FilterToolService)

        // Apply the selected filter
        this.productService.product.subscribe(product => {

            withFilters.forEach(function (filter) {
                self.applyFilterOn(object, filter)
            });
        })

        // automatically apply filters selected in backed if the product is not in editor mode
        if(!this.config.isPresentationMode()) return

        // Apply the selected filter
        this.productService.product.subscribe(product => {
            if(product.selected_filter)
                this.applyFilterOn(object, product.selected_filter);
            else {
                // apply first viable filter - this get applied in presentation mode (first condition in this function)
                // remove "nofilter"
                let filters = product.filters
                const index = filters.indexOf("nofilter");
                if (index > -1)
                    filters.splice(index, 1);
                if(filters.length)
                    this.applyFilterOn(object, filters[0]);
            }
        });


        // Apply all available filters
        // this.productService.product.subscribe(product => {
        //     for(var filter of product.filters)
        //         this.applyFilterOn(object, filter);
        // });
    }

    /**
     * This functio is modified from FilterToolService
     * FilterToolService cannot be used injected and used with success because of a cyclic dependency
     * @param {string} filterName
     */
    private applyFilterOn(image: Image, filterName: string) {

        const filter = this.filterTool.getByName(filterName);
        const newFilter = this.filterTool.create(filter);

        image.filters.push(newFilter);
        image.applyFilters();

        this.render();
    }

    public clipToImprintArea(ctx, self) {
        if(!self.imprintArea) return;

        //var self = this;
        var vt = self.state.fabric.viewportTransform;
        var x = self.state.fabric.getRetinaScaling();

        ctx.save();
        ctx.setTransform(x * vt[0], 0, 0, x * vt[3], vt[4], vt[5]);

        self.imprintArea.render(ctx);

        ctx.restore();
    }

    private scaleImageToFitImprintArea(image: Image) {

        if (!image) return;

        if(this.imprintArea) {
            var aspectSize = calculateAspectRatioFit(
                image.getScaledWidth(),
                image.getScaledHeight(),
                this.imprintArea.getScaledWidth(),
                this.imprintArea.getScaledHeight(),
            )

            image.scaleToWidth(aspectSize.width);
            image.scaleToHeight(aspectSize.height);
        }
    }

    /**
     * Center image in parent container.
     * This could be either the imprint are or the canvas viewport
     * @param {Image} image
     */
    private centerImageInContainer(image: Image) {

        if (!this.imprintArea) {
            image.viewportCenter()
            return
        }

        image.setPositionByOrigin(this.imprintArea.getCenterPoint(), 'center', 'center');
    }

    public loadImprintArea() {
        //clear all previous imprint areas
        this.imprintArea = null
        let existingImprintArea = this.state.fabric.getObjects().find(obj => obj.name === ObjectNames.imprintArea.name);
        this.state.fabric.remove(existingImprintArea);

        // check to see if the product has an imprint area defined and load it
        this.loadProductImprintArea()

        // load default imprint are only for BACKEND
        if(!this.imprintArea && this.config.isBackendMode())
            this.loadDefaultImprintArea()

        // if is presentation mode hide the imprint area by default
        if(this.config.isPresentationMode())
            this.hideImprintArea(true)

        if(this.config.get('logostudio.ui.lockSvgArea')) {
            if(!this.imprintArea) return;
            this.imprintArea.selectable = false
        }

        // disable integration for Presentation Mode
        // !!! + CSS sourcecode/src/app/app.component.scss added pointer events to none
        if(this.config.isPresentationMode()) {
            this.state.fabric.selection = false;
            this.state.fabric.isDrawingMode = false;
            this.state.fabric.forEachObject(function(o) {
                o.selectable = false;
                o.evented = false;
            });
            this.state.fabric.renderAll();
        }
    }

    private loadProductImprintArea() {

        var svgMask: string = this.product.selected_image.svg_imprint_area;

        if(!svgMask) return;

        var self = this;
        fabric.loadSVGFromString(svgMask, function(objects, options) {
            self.imprintArea = fabric.util.groupSVGElements(objects, options);

            self.imprintArea.setCoords();

            self.imprintArea.set({
                name: ObjectNames.imprintArea.name,
                //clipFor: ObjectNames.image.name,
                objectCaching: false, // !! critical for the clipping to work
                lockUniScaling: false, //
            });

            self.state.fabric.add(self.imprintArea).renderAll();
            self.state.fabric.discardActiveObject();
        })
    }

    private loadDefaultImprintArea() {
        var self = this;
        var svgArea = '<svg width="200" height="100" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"><rect vector-effect="non-scaling-stroke" stroke="#bbbbbb" stroke-dasharray="10,5" stroke-width="2" fill-opacity="0"  shape-rendering="crispEdges" height="100" width="200" y="0" x="0"/></svg>';

        fabric.loadSVGFromString(svgArea, function(objects, options) {
            self.imprintArea = fabric.util.groupSVGElements(objects, options);

            self.imprintArea.name = ObjectNames.imprintArea.name;
            self.imprintArea.left = (self.state.fabric.getWidth() - self.imprintArea.width) / 2;
            self.imprintArea.top = (self.state.fabric.getHeight() - self.imprintArea.height) / 2;

            self.imprintArea.setCoords();
            self.imprintArea.set({
                lockRotation: false,
                objectCaching: false, // !! critical for the clipping to work
                lockUniScaling: false, //
                // hasRotatingPoint: false
                // hasControls: false,
            });
            self.imprintArea.setControlsVisibility({
                tl:true, //top-left
                mt:true, // middle-top
                tr:true, //top-right
                ml:true, //middle-left
                mr:true, //middle-right
                bl:true, // bottom-left
                mb:true, //middle-bottom
                br:true //bottom-right
            })

            self.state.fabric.add(self.imprintArea).renderAll();
            self.state.fabric.discardActiveObject();
        });
    }

    public saveImprintArea(imprintAreaData: object = null, callback) {
        //append to the imprint area data the size in pixels of the area
        imprintAreaData["width_px"] = this.imprintArea.getScaledWidth();
        imprintAreaData["height_px"] = this.imprintArea.getScaledHeight();

        this.http.post(
            this.config.getSaveSvgEndpoint(),
            {
                idProduct: this.product.id,
                idImage: this.product.selected_image.id,
                svg: this.imprintArea.toSVG(),
                imprintAreaData: imprintAreaData
            }
        ).subscribe(() => {callback()}, () => {});
    }

    public saveDesign(callback) {

        // save design if we have an ongoing session and a logo
        if(!this.productService.uploadedLogo || !this.productService.session_id) {
        //if(!this.productService.session_id) {
            if(callback) callback(false, "Session is missing. Please setup a valid session!")
            return;
        }

        // hide the imprint area so it does not show in the render
        this.hideImprintArea(true);

        var data = this.state.fabric.toDataURL({format: 'png'})
           // blob = new Blob([data], { type: "image/png" });

        // get logo modifications


        this.http.post(
            this.config.getSaveDesignEndpoint(),
            {
                session_id: this.productService.session_id,
                id_product: this.product.id,
                id_image: this.product.selected_image.id,
                id_uploaded_logo: this.productService.uploadedLogo.id,
                canvas: data,//blob.toString()
                logo_width_px: this.getObjectByName(ObjectNames.image.name).getScaledWidth(),
                logo_height_px:this.getObjectByName(ObjectNames.image.name).getScaledHeight(),
                filter: this.product.selected_filter,
            }
        ).subscribe(() => {
            // show imprint area back again
            this.hideImprintArea(false);

            if(callback) callback(true)
        }, () => {
            if(callback) callback(false)
        });
    }

    private hideImprintArea(hide: boolean) {
        if(!this.imprintArea) return;

        // also do not hide svg if in backend mode
        if(this.config.isBackendMode()) return;

        if(this.hideImprintArea$) {
            this.imprintArea.strokeWidth = 0;
            return
        }

        this.imprintArea.strokeWidth = (hide ? 0 : 2);
    }

    /**
     * Return one logo. This must change when we have multiple logos
     */
    public getLogo() : Image {
        return this.getObjectByName(ObjectNames.image.name) as Image
    }

    /* Helpers */
    public getObjectByName(name: String) {
        return _.find(this.state.fabric._objects, function(o) { return o.name === name })
    }




}
