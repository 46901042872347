import {Action, Actions, NgxsOnInit, Selector, State, StateContext, Store} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {BaseToolState} from "../base-tool.state";
import {DrawerName} from "../../toolbar-controls/drawers/drawer-name.enum";
import {ApplyChanges, CancelChanges, CloseForePanel, OpenPanel} from "../../../image-editor/state/editor-state-actions";
import {HistoryToolService} from "../../../image-editor/history/history-tool.service";
import {ShapesToolService} from "../../../image-editor/tools/shapes/shapes-tool.service";
import {CanvasService} from "../../../image-editor/canvas/canvas.service";
import {AddShape} from "../shapes/shapes.actions";
import {ChangeProductImage} from "./product.actions";
import {HistoryNames} from "../../../image-editor/history/history-names.enum";
import {Settings} from "@common/core/config/settings.service";
import {ProductService} from "../../../services/product.service";

interface ProductStateModel {
    dirty: boolean;
}

@State<ProductStateModel>({
    name: 'product',
    defaults: {
        dirty: false,
    }
})
@Injectable()
export class ProductState extends BaseToolState<ProductStateModel> implements NgxsOnInit {

    protected toolName = DrawerName.SHAPES;

    @Selector()
    static dirty(state: ProductStateModel) {
        return state.dirty;
    }

    constructor(
        protected canvas: CanvasService,
        protected history: HistoryToolService,
        private config: Settings,
        protected actions$: Actions,
        public productService: ProductService
    ) {
        super();
    }

    applyChanges(ctx: StateContext<ProductStateModel>, action: ApplyChanges) {
        if (ctx.getState().dirty) {
            this.history.add(HistoryNames.SHAPES);
        }
        ctx.patchState({dirty: false});
    }

    cancelChanges(ctx: StateContext<ProductStateModel>, action: CancelChanges) {
        if (ctx.getState().dirty) {
            this.history.reload();
        }
        ctx.patchState({dirty: false});
    }
    resetState(ctx: StateContext<ProductStateModel>, action: CancelChanges) {
        ctx.setState({dirty: false});
    }

    @Action(ChangeProductImage)
    changeProductImage(ctx: StateContext<ProductStateModel>, action: ChangeProductImage) {
        ctx.patchState({dirty: true});

        this.productService.setSelectedImage(action.image);
        this.config.set('logostudio.image', action.image.url);
        this.canvas.loadMainImage(action.image.url, false).then(() => {
        //     this.activeObject.init();
        //     this.canvasKeybinds.init();
        //     this.fitCanvasToScreenOnResize();
        //     this.openObjectSettingsOnDoubleClick();
        //     this.closePanelsOnObjectDelete();
        //     this.handleObjectSelection();
        //     this.updateHistoryOnObjectModification();
        //     this.bindToClickOutsideCanvas();
        //     this.ignoreMobileKeyboard();
        //     this.canvasMaskWrapper.nativeElement.classList.remove('not-loaded');
        });
    }
}