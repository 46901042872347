import {ChangeDetectionStrategy, Component} from '@angular/core';
import {startCase} from '@common/core/utils/start-case';
import {Select} from '@ngxs/store';
import {EditorState} from '../../../../image-editor/state/editor-state';
import {Observable} from 'rxjs';
import {DrawerName} from '../../drawers/drawer-name.enum';
import {Product} from "../../../../models/product.model";

@Component({
    selector: 'toolbar-product-name-widget',
    templateUrl: './toolbar-product-name-widget.component.html',
    styleUrls: ['./toolbar-product-name-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarProductNameWidgetComponent {
    @Select(EditorState.product) product$: Observable<Product>;
}
