

//https://nehalist.io/working-with-models-in-angular/
//https://angular.io/tutorial/toh-pt6

import {ProductImage} from "./product-image.model";
import {Deserializable} from "./deserializable.model";

export class ProductColor implements Deserializable {
    id: string;
    color_code: string;
    color_url: string;
    images: ProductImage[]; // images are set in product model to avoid infinte loop cause by deserializing

    deserialize(input: any): this {

        let obj = input

        Object.assign(this, obj);

        return this;
    }

    public isEqual(obj) {
        return this.id == obj.id;
    }
}