import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {OverlayPanelRef} from 'common/core/ui/overlay-panel/overlay-panel-ref';
import {Select} from '@ngxs/store';
import {HistoryState} from '../../state/history/history.state';
import {Observable} from 'rxjs';
import {matDialogAnimations} from '@angular/material/dialog';
import {CanvasService} from "../../../image-editor/canvas/canvas.service";
import {EditorState} from "../../../image-editor/state/editor-state";
import {ProductUI} from "../../../image-editor/default-settings";
import {ProductUIMode} from "../../../image-editor/enums/product-ui";
import {Settings} from "@common/core/config/settings.service";
import {startCase} from "@common/core/utils/start-case";
import {FilterToolService} from "../../../image-editor/tools/filter/filter-tool.service";
import {FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProductService} from "../../../services/product.service";

@Component({
    selector: 'imprint-area-panel',
    templateUrl: './imprint-area-panel.component.html',
    styleUrls: ['./imprint-area-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[@dialogContainer]': `'enter'`,
        '[class.floating-panel]': 'true',
    },
    animations: [
        matDialogAnimations.dialogContainer,
    ]
})

export class ImprintAreaPanelComponent {

    @Select(EditorState.productUI) productUI$: Observable<ProductUI>;

    public showImprintAreaPanel$: boolean;

    public imprintForm: FormGroup;

    public filterList: string[];
    private selectedFilterList: string[] = [];
    public width:number;
    public height:number;

    constructor(
        public panelRef: OverlayPanelRef,
        public canvasService: CanvasService,
        public productService: ProductService,
        public filterTool: FilterToolService,
        public config: Settings,
        private formBuilder: FormBuilder
    ) {
        this.filterList = this.config.get('logostudio.tools.filter.items');

        // https://coryrylan.com/blog/creating-a-dynamic-checkbox-list-in-angular
        this.imprintForm = this.formBuilder.group({
            filters: new FormArray([]),
            width: null,
            height: null
        });

        this.productService.product.subscribe(
            (product) => {
                if(product) {
                    // add filters if the images has them
                    this.clearFilters()

                    let filters = product.selected_image.filters;
                    for(var filter of filters) {
                        this.addFilter(filter)
                    }
                    this.addFilterCheckboxes()

                    // add logo physical dimensions
                    this.imprintForm.controls.width.setValue(product.selected_image.imprint_area_width_cm)
                    this.imprintForm.controls.height.setValue(product.selected_image.imprint_area_height_cm)
                }
            }
        )
    }

    public ngOnInit() {
        this.productUI$.subscribe(
            (productUI) => {
                if(productUI) {
                    this.showImprintAreaPanel$ = productUI.mode == ProductUIMode.BACKEND
                }
            }
        )
    }

    get imprintFormFiltersArray() {
        return this.imprintForm.controls.filters as FormArray;
    }

    private addFilterCheckboxes() {
        // this.selectedFilterList.forEach(function (filter) {
        //     let filterCb = this.imprintForm.get(filter) as FormControl;
        //     filterCb.setValue(true)
        // });

        this.filterList.forEach((filter) => this.imprintFormFiltersArray.push(new FormControl(this.isChecked(filter))));
    }

    private clearFilters() {
        this.imprintForm.controls.filters = new FormArray([])
        this.selectedFilterList = []
    }

    public isChecked(filter) {
        return this.selectedFilterList.indexOf(filter) > -1
    }

    public saveImprintArea() {
        let imprintAreaData = {
            filters: this.selectedFilterList,
            width: this.imprintForm.value.width,
            height: this.imprintForm.value.height
        }

        this.canvasService.saveImprintArea(imprintAreaData, function () {
            // TODO - fix this --see comment in addFilter function
            location.reload()
        })
    }

    public getFilterDisplayName(name: string): string {
        return this.filterTool.getDisplayName(name);
    }

    public addFilter(name: string) {
        var idx = this.selectedFilterList.indexOf(name);
        if (idx > -1) {
            this.selectedFilterList.splice(idx, 1);
        }
        else {
            this.selectedFilterList.push(name);
        }

        // update the product image with the filters
        // TODO - now we just refresh the page to make sure we have the latest filter loaded
    }
}
