

//https://nehalist.io/working-with-models-in-angular/
//https://angular.io/tutorial/toh-pt6

import {Deserializable} from "./deserializable.model";

export class UploadedLogo implements Deserializable {
    id: string;
    session_id: string;
    original_file_name: string;
    saved_original_file_name: string;
    original_file_extension: string;
    logo_file_name: string;
    logo_file_extension: string;
    save_path: string;
    file_path: string;
    file_url: string;
    logo_path: string;
    logo_url: string;

    deserialize(input: any): this {

        let obj = input.data

        Object.assign(this, obj);

        return this;
    }

    public isEqual(obj) {
        return this.id == obj.id;
    }
}
