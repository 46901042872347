<div class="tool-panel-content" *ngIf="!(hideProductImage$)">

    <div class="colors" *ngIf="(colors && colors.length > 1)">
        <button
                class="no-style color control"
                (click)="changeColor(color)"
                *ngFor="let color of colors"
                [style.background]="color.color_code"
                [class.selected]="selectedColor$.value.color_code === color.color_code"
        ></button>
    </div>

    <div class="images" *ngIf="(images && images.length > 1)">
        <button class="button-with-image" *ngFor="let image of images" (click)="changeProductImage(image);">
            <img [attr.src]="image.url">
        </button>
    </div>
</div>