import {ElementRef, Injectable} from '@angular/core';
import {Settings} from 'common/core/config/settings.service';
import {OverlayPanel} from 'common/core/ui/overlay-panel/overlay-panel.service';
import {OverlayPanelRef} from 'common/core/ui/overlay-panel/overlay-panel-ref';
import {CanvasStateService} from '../../../image-editor/canvas/canvas-state.service';
import {Store} from '@ngxs/store';
import {EditorState} from '../../../image-editor/state/editor-state';
import {LogoPreviewPanelComponent} from "./logo-preview-panel.component";
import {UploadedLogo} from "../../../models/uploaded-logo.model";

@Injectable({
    providedIn: 'root',
})
export class LogoPreviewPanelService {
    private logoPreviewPanelRef: OverlayPanelRef<LogoPreviewPanelComponent>;

    constructor(
        private canvasState: CanvasStateService,
        private config: Settings,
        private overlayPanel: OverlayPanel,
        private store: Store,
    ) {}

    public open(uploadedLogo: UploadedLogo) {
        const positionStrategy = this.overlayPanel.overlay.position()
            .flexibleConnectedTo(new ElementRef(this.canvasState.wrapperEl))
            .withPositions([{overlayX: 'center', overlayY: 'center', originX: 'center', originY: 'center'}]);

        this.logoPreviewPanelRef = this.overlayPanel.open(
            LogoPreviewPanelComponent,
            {
                hasBackdrop: true,
                closeOnBackdropClick: false,
                positionStrategy: positionStrategy,
                panelClass: ['floating-panel', 'sample-image-panel']
            }
        );

        // pass the logo the view
        (this.logoPreviewPanelRef.componentRef.instance as LogoPreviewPanelComponent).currentLogo = uploadedLogo

        this.logoPreviewPanelRef.afterClosed().subscribe(() => {
            this.logoPreviewPanelRef = null;
        });
    }

    public reposition() {
        if ( ! this.logoPreviewPanelRef) return;
        this.logoPreviewPanelRef.updatePosition();
    }

    /**
     * Check if "open image" dialog window should be shown.
     */
    private shouldShowOpenImageDialog() {
        return this.store.selectSnapshot(EditorState.visible) &&
            !this.logoPreviewPanelRef;
    }
}
