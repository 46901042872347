import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {OverlayPanelRef} from 'common/core/ui/overlay-panel/overlay-panel-ref';
import {Select} from '@ngxs/store';
import {HistoryState} from '../../state/history/history.state';
import {Observable} from 'rxjs';
import {matDialogAnimations} from '@angular/material/dialog';
import {CanvasService} from "../../../image-editor/canvas/canvas.service";
import {EditorState} from "../../../image-editor/state/editor-state";
import {ProductUI} from "../../../image-editor/default-settings";
import {ProductUIMode} from "../../../image-editor/enums/product-ui";
import {HistoryNames} from "../../../image-editor/history/history-names.enum";
import {ImportToolService} from "../../../image-editor/tools/import/import-tool.service";
import {HistoryToolService} from "../../../image-editor/history/history-tool.service";
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'upload-logo-panel',
    templateUrl: './upload-logo-panel.component.html',
    styleUrls: ['./upload-logo-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[@dialogContainer]': `'enter'`,
        '[class.floating-panel]': 'false',
    },
    animations: [
        matDialogAnimations.dialogContainer,
    ]
})

export class UploadLogoPanelComponent {

    @Select(EditorState.productUI) productUI$: Observable<ProductUI>;

    public showUploadLogoPanel$: boolean;
    public showSaveDesign$: boolean;

    constructor(
        public panelRef: OverlayPanelRef,
        public importTool: ImportToolService,
        public canvas: CanvasService,
        private history: HistoryToolService,
        private snackBar: MatSnackBar
    ) {}

    public ngOnInit() {
        this.productUI$.subscribe(
            (productUI) => {
                if(productUI) {
                    this.showUploadLogoPanel$ = productUI.showUploadLogoPanel
                    this.showSaveDesign$ = productUI.showSaveDesign
                }
            }
        )
    }

    public uploadLogo() {
        this.importTool.openUploadDialog().then(obj => {
            if ( ! obj) return;
            this.canvas.fabric().setActiveObject(obj);
            this.history.add(HistoryNames.LOGO_IMAGE);
        });
    }

    public saveDesign() {
        let self = this
        this.canvas.saveDesign(function (isSuccess, message) {
            if(isSuccess)
                self.snackBarMessage(message ? message : "Design saved!")
            else
                self.snackBarMessage(message ? message : "Something went wrong", true)
        })
    }

    private snackBarMessage(message: string, isError: boolean = false) {

        if(isError) {
            this.snackBar.open(message, null, {
                duration: 4000,
                panelClass: ['error-snackbar']
            });
        }
        else {
            this.snackBar.open(message, null, {
                duration: 2000,
            });
        }
    }
}
