import {Injectable} from '@angular/core';
import {fabric} from 'fabric';
import {Filter, filtersList} from './filters-list';
import {CanvasService} from '../../canvas/canvas.service';
import {IBaseFilter, Image} from 'fabric/fabric-impl';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {ApplyFilter, RemoveFilter, SetAppliedFilters} from '../../../image-editor-ui/state/filter/filter.actions';
import {HistoryChanged} from '../../../image-editor-ui/state/history/history.actions';
import {ucFirst} from '@common/core/utils/uc-first';
import {ActiveObjectService} from "../../canvas/active-object/active-object.service";
import {startCase} from "@common/core/utils/start-case";

@Injectable()
export class FilterToolService {
    private filtersList = filtersList;
    public appliedFilters: string[][]; // for each ActiveObjectService

    constructor(
        private canvas: CanvasService,
        private activeObject: ActiveObjectService,
        private store: Store,
        private actions$: Actions,
    ) {
        this.syncWithHistory();

        this.appliedFilters = [];
    }

    public apply(filterName: string) {
        const filter = this.getByName(filterName);
        if (this.applied(filter.name)) {
            return this.remove(filter.name);
        }

        // update the applied filters list. This can be moved in ActiveObject
        if(!this.appliedFilters[this.activeObject.getId()])
            this.appliedFilters[this.activeObject.getId()] = [];
        this.appliedFilters[this.activeObject.getId()].push(filter.name);

        this.store.dispatch(new ApplyFilter(filter.name));
        const newFilter = this.create(filter);

        // this.getImages().forEach(image => {
        //     image.filters.push(newFilter);
        //     image.applyFilters();
        // });

        this.getImage().filters.push(newFilter);
        this.getImage().applyFilters();

        this.canvas.render();
    }

    public remove(filterName: string) {
        const filter = this.getByName(filterName);

        if(!this.appliedFilters[this.activeObject.getId()])
            return;

        const index = this.appliedFilters[this.activeObject.getId()].indexOf(filter.name);
        if (index > -1) {
            this.appliedFilters[this.activeObject.getId()].splice(index, 1);
        }

        this.store.dispatch(new RemoveFilter(filter.name));

        const i = this.findFilterIndex(filter.name, this.getImage().filters);
        this.getImage().filters.splice(i, 1);
        this.getImage().applyFilters();

        // this.getImages().forEach(image => {
        //     const i = this.findFilterIndex(filter.name, image.filters);
        //     image.filters.splice(i, 1);
        //     image.applyFilters();
        // });

        this.canvas.render();
    }

    public getAll(): Filter[] {
        return this.filtersList;
    }

    public getAppliedFiltersForActiveObject() {
        if(this.appliedFilters[this.activeObject.getId()])
            return this.appliedFilters[this.activeObject.getId()];
        return [];
    }

    public getByName(name: string): Filter {
        return this.filtersList.find(filter => filter.name === name);
    }

    public applied(name: string): boolean {
        const mainImage = this.canvas.getMainImage();
        if ( ! mainImage) return;
        return this.findFilterIndex(name, mainImage.filters) > -1;
    }

    public hasOptions(name: string) {
        return !!this.getByName(name).options;
    }

    private findFilterIndex(name: string, filters: IBaseFilter[]): number {
        if ( ! filters.length) return;

        const filter = this.getByName(name);

        return filters.findIndex(curr => {
            const type = curr.type.toLowerCase();

            // match by type
            if (type === name.toLowerCase()) return true;

            // match by matrix
            return type === 'convolute' && this.matrixAreEqual(filter.matrix, curr['matrix']);
        });
    }

    private matrixAreEqual(matrix1: number[], matrix2: number[]): boolean {
        if ( ! matrix1 || ! matrix2 || matrix1.length !== matrix2.length) return false;

        for (let i = matrix1.length; i--;) {
            if (matrix1[i] !== matrix2[i]) return false;
        }

        return true;
    }

    public applyValue(filterName: string, optionName: string, optionValue: number|string) {
        const filter = this.getByName(filterName);

        const fabricFilter = this.getImage().filters.find(curr => {
            return curr.type.toLowerCase() === filter.name.toLowerCase();
        });

        fabricFilter[optionName] = optionValue;

        // filter has a special "resize" function that needs to be invoked
        if (filter.apply) filter.apply(fabricFilter, optionName, optionValue);

        this.getImage().applyFilters();

        // this.getImages().forEach(image => {
        //     const fabricFilter = image.filters.find(curr => {
        //         return curr.type.toLowerCase() === filter.name.toLowerCase();
        //     });
        //
        //     fabricFilter[optionName] = optionValue;
        //
        //     // filter has a special "resize" function that needs to be invoked
        //     if (filter.apply) filter.apply(fabricFilter, optionName, optionValue);
        //
        //     image.applyFilters();
        // });

        this.canvas.render();
    }

    public create(filter: Filter): IBaseFilter {
        if(!filter) return;
        
        const initialConfig = filter.initialConfig || {};
        if (filter.uses) {
            initialConfig.matrix = filter.matrix;
            return new fabric.Image.filters[ucFirst(filter.uses)](initialConfig);
        } else {
            for (const key in filter.options) {
                initialConfig[key] = filter.options[key].current;
            }
            return new fabric.Image.filters[ucFirst(filter.name)](initialConfig);
        }
    }

    public addCustom(name: string, filter: object, editableOptions?: object, initialConfig?: object) {
        fabric.Image.filters[ucFirst(name)] = fabric.util.createClass(fabric.Image.filters.BaseFilter, filter);
        fabric.Image.filters[ucFirst(name)].fromObject = fabric.Image.filters.BaseFilter['fromObject'];
        filtersList.push({name, options: editableOptions, initialConfig});
    }

    public getDisplayName(filterName: string): string {
        var filter = this.getByName(filterName);

        if(filter && filter.displayName)
            return filter.displayName

        if (filterName === 'blackWhite')
            return 'Black & White'

        return startCase(filterName);
    }

    private getImages(): Image[] {
        return  [this.canvas.fabric().getActiveObject()] as Image[];
        //return this.canvas.fabric().getObjects(ObjectNames.image.name) as Image[];
    }

    private getImage(): Image {
        var activeLogo = this.canvas.fabric().getActiveObject() as Image
        if(!activeLogo)
            activeLogo = this.canvas.getLogo();

        return  activeLogo
    }

    private syncWithHistory() {
        this.actions$.pipe(
            ofActionSuccessful(HistoryChanged),
        ).subscribe(() => {
            const names = this.getAll()
                .filter(filter => this.applied(filter.name))
                .map(filter => filter.name);
            this.store.dispatch(new SetAppliedFilters(names));
        });
    }
}
