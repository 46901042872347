import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {Settings} from 'common/core/config/settings.service';
import {FilterToolService} from '../../../../image-editor/tools/filter/filter-tool.service';
import {Select, Store} from '@ngxs/store';
import {EditorState} from '../../../../image-editor/state/editor-state';
import {Observable} from 'rxjs';
import {OpenFilterControls} from '../../../state/filter/filter.actions';
import {FilterState} from '../../../state/filter/filter.state';
import {DrawerName} from '../drawer-name.enum';
import {startCase} from '@common/core/utils/start-case';
import {BOTTOM_POSITION} from "@common/core/ui/overlay-panel/positions/bottom-position";
import {BehaviorSubject} from "rxjs/index";
import {OverlayPanel} from "@common/core/ui/overlay-panel/overlay-panel.service";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ColorpickerPanelComponent} from "@common/core/ui/color-picker/colorpicker-panel.component";
import {ColorWidgetComponent} from "../../widgets/color-widget/color-widget.component";
import {ActiveObjectService} from "../../../../image-editor/canvas/active-object/active-object.service";
import {ProductService} from "../../../../services/product.service";

@Component({
    selector: 'filter-drawer',
    templateUrl: './filter-drawer.component.html',
    styleUrls: ['./filter-drawer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {'class': 'controls-drawer'},
    // providers: [{
    //     provide: NG_VALUE_ACCESSOR,
    //     useExisting: FilterDrawerComponent,
    //     multi: true,
    // }]
})
/*
export class FilterDrawerComponent implements ControlValueAccessor {

    public selectedFilter$ = new BehaviorSubject<string>(null);
    public activeFilters$ = new BehaviorSubject<string[][]>([]);
    public filterList: string[];
    public propagateChange: Function;

    constructor(
        public activeObject: ActiveObjectService,
        private settings: Settings,
        public filterTool: FilterToolService,
        protected store: Store,
        public config: Settings,
    ) {
        this.filterList = this.config.get('logostudio.tools.filter.items');

        const activeFilters = [];
        this.activeFilters$.next(activeFilters);
    }

    public applyFilter(filter: string) {
        this.filterTool.apply(filter);

    console.log(this.activeFilters$);

        if(!this.activeFilters$['a'])
            this.activeFilters$['a'] = [];
    console.log(this.activeFilters$);
        const activeFilters =  [...this.activeFilters$['a'].value];
        activeFilters['a'].push(filter);
        this.activeFilters$.next(activeFilters);
    console.log(this.activeFilters$);
        // this.propagateChange(filter);
    }

    public removeFilter(filter: string) {
        this.filterTool.remove(filter);

        const activeFilters = [...this.activeFilters$['a'].value];
        const i = activeFilters.indexOf(filter);
        activeFilters.splice(i, 1);
        this.activeFilters$.next(activeFilters);
    }

    public filterIsApplied(filter: string) {
        if(!this.activeFilters$['a'])
            return false;

        const activeFilters =  [...this.activeFilters$['a'].value];
        if(activeFilters.indexOf(filter) > -1)
            return true;
        return false;
    }

    public getFilterImage(filter: string) {
        return this.settings.getAssetUrl('images/filters/square/' + filter.replace(' ', '-') + '.jpg', true);
    }


    // see below coommented function for a way to open the controls
    public showFilterControls(filter: string) {
        this.store.dispatch(new OpenFilterControls(filter));
    }

    // public openColorPicker() {
    //     const config = {position: BOTTOM_POSITION, hasBackdrop: true, origin: this.colorPickerButton};
    //     this.overlayPanel.open(ColorpickerPanelComponent, config)
    //         .valueChanged().subscribe(color => this.changeColor(color));
    // }

    public getFilterDisplayName(name: string): string {
        var filter = this.filterTool.getByName(name);

        if(filter && filter.displayName) {
            return filter.displayName
        }

        if (name === 'blackWhite') return 'Black & White';
        return startCase(name);
    }

    public writeValue(value: string|null) {
        this.selectedFilter$.next(value);
    }

    public registerOnChange(fn: Function) {
        this.propagateChange = fn;
    }

    public registerOnTouched() {}
}
*/

export class FilterDrawerComponent {
    @Select(EditorState.activePanel) forePanel$: Observable<DrawerName>;
    @Select(FilterState.activeFilters) activeFilters$: Observable<string[]>;
    @Select(FilterState.selectedFilter) selectedFilter$: Observable<string|null>;
    @Select(FilterState.dirty) dirty$: Observable<boolean>;

    public filterList: string[];

    constructor(
        private settings: Settings,
        public filterTool: FilterToolService,
        public productService: ProductService,
        protected store: Store,
        public config: Settings,
    ) {

        this.productService.product.subscribe(
            (product) => {
                if(product && product.selected_image.filters.length) {
                    // set filters if the images has them and are also exists in LS - eliminates 'no filter'
                    let backendFilters = product.selected_image.filters;
                    let lsFilters = this.config.get('logostudio.tools.filter.items');

                    this.filterList = backendFilters.filter(value => lsFilters.includes(value));
                }
                else {
                    this.filterList = this.config.get('logostudio.tools.filter.items');
                }
            }
        )

    }

    public applyFilter(filter: string) {
        this.filterTool.apply(filter);
    }

    public removeFilter(filter: string) {
        this.filterTool.remove(filter);
    }

    public getFilterImage(filter: string) {
        return this.settings.getAssetUrl('images/filters/square/' + filter.replace(' ', '-') + '.jpg', true);
    }

    public showFilterControls(filter: string) {
        this.store.dispatch(new OpenFilterControls(filter));
    }

    public getFilterDisplayName(name: string): string {
        return this.filterTool.getDisplayName(name);
    }
}
