<div *ngIf="(showImprintAreaPanel$)">
    <div class="header">
        <div class="name" trans>Imprint area</div>
    </div>
    <div customScrollbar>
        <form [formGroup]="imprintForm">

            <section class="print-size-section">
                Width(cm) <input type="number" formControlName="width" name="width"><br>
                Height(cm) <input type="number" formControlName="height" name="height">
            </section>

            <section class="print-techniques-section">
                <ul class="print-techniques">
                    <li *ngFor="let filter of imprintFormFiltersArray.controls;let i = index" formArrayName="filters">
                        <input type="checkbox" mat-checkbox [formControlName]="i" (change)="addFilter(filterList[i])">{{ getFilterDisplayName(filterList[i]) }}
                    </li>
                </ul>
            </section>

            <div class="p">
                <button type="button" mat-raised-button color="accent" trans (click)="saveImprintArea()">Save</button>
            </div>
        </form>
    </div>
</div>