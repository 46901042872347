import {Deserializable} from "./deserializable.model";
import {ImprintArea} from "./imprint-area";
import {ProductColor} from "./product-color.model";

export class ProductImage implements Deserializable {
    id: string;
    id_external: string;
    name: string;
    url: string;
    color: ProductColor;
    svg_imprint_area: string;
    filters: string[]
    imprint_area_width_cm: number
    imprint_area_height_cm: number

    imprintArea: ImprintArea

    deserialize(input: any): this {

        let obj = input

        Object.assign(this, obj);
        this.id = String(input.id)
        this.filters = JSON.parse(input.filters)

        if(obj.color)
           this.color = new ProductColor().deserialize(obj.color);

        return this;
    }
}
