<h2 trans>Adjust white background</h2>

<div class="current-logo">
    <img [src]="currentLogo.logo_url" class=" mat-elevation-z2">
    <div class="operations">
        <mat-checkbox class="mar" (change)="removeWhite($event.checked)" trans>Remove white</mat-checkbox>
    </div>
</div>

<div class="buttons">
    <button mat-flat-button (click)="close()" trans>Close</button>
    <button mat-flat-button color="accent" (click)="openLogo()" trans>Open</button>
</div>

<!--<div class="separator" *ngIf="sampleImages.length">-->
    <!--<hr>-->
    <!--<span class="text" trans>or use a sample</span>-->
<!--</div>-->

<!--<div class="samples" customScrollbar>-->
    <!--<img [src]="getSampleUrl(image, true)" *ngFor="let image of sampleImages" (click)="openSample(image)">-->
<!--</div>-->
